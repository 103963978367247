import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ComponentLinks, FigmaEmbed } from '../common';
import { Button, ImageCropper, Space } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { useState } from 'react';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "imagecropper"
    }}>{`ImageCropper`}</h1>
    <ComponentLinks name={'ImageCropper'} figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=29680%3A36684'} storybook={'/?path=/story/imagecropper-imagecropper--playground'} vueStorybook={'/?path=/story/imagecropper-imagecropper--playground'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`ImageCropper — это компонент, который позволяет настроить изображение под нужный размер или разрешение, а также повернуть изображение с шагом в 45° или заменить на другое.`}</p>
    <p>{`ImageCropper, как и модальное окно, появляется поверх родительского экрана, главная страница уходит на второй план, но при этом остаётся в поле зрения. Пользователь может выполнить какое-либо нужное ему действие или вернуться к основному окну. ImageCropper можно закрыть крестиком, либо окно кроппера закроется само, после сохранения настроек изображения.`}</p>
    <p>{`В панели редактирования изображения пользователь может выбрать предлагаемый ему формат изображения. Если же пользователь изменяет формат вручную, кнопки с предлагаемым форматом не выделяются.`}</p>
    <p>{`Для управления видимостью ImageCropper используйте пропы `}<inlineCode parentName="p">{`isOpen`}</inlineCode>{` и `}<inlineCode parentName="p">{`onCloseClick`}</inlineCode>{`. Ссылку на изображение необходимо передать в проп `}<inlineCode parentName="p">{`src`}</inlineCode>{`.`}</p>
    <p>{`Компонент поддерживает два типа сохранения изображения: в виде `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/ru/docs/Web/API/Blob"
      }}>{`Blob`}</a>{` и `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/ru/docs/Web/HTTP/Basics_of_HTTP/Data_URIs"
      }}>{`Data URL`}</a>{`. Для сохранения Blob передайте колбек `}<inlineCode parentName="p">{`onSaveBlob`}</inlineCode>{`. Для сохранения Data URL передайте `}<inlineCode parentName="p">{`onSaveDataUrl`}</inlineCode>{`. Подробнее смотрите в примере ниже.`}</p>
    <Playground __position={1} __code={'() => {\n  const src = \'https://picsum.photos/1280/720\'\n  const [croppedImageUrl, setCroppedImageUrl] = useState(null)\n  const [isOpen, setIsOpen] = useState(false)\n  const onSaveBlob = image => {\n    const formData = new FormData()\n    formData.append(\'image\', image)\n    // fetch(\'/api/formdata\', { method: \'POST\', body: formData })\n    if (croppedImageUrl) URL.revokeObjectURL(croppedImageUrl)\n    setCroppedImageUrl(URL.createObjectURL(image))\n  }\n  const onSaveDataUrl = image => {\n    // fetch(\'/api/base64\', { method: \'POST\', body: image })\n  }\n  const onClose = () => {\n    setIsOpen(false)\n  }\n  return (\n    <div>\n      <ImageCropper\n        isOpen={isOpen}\n        src={src}\n        onSaveBlob={onSaveBlob}\n        onSaveDataUrl={onSaveDataUrl}\n        onCloseClick={onClose}\n      />\n      <Button onClick={() => setIsOpen(true)}>Открыть ImageCropper</Button>\n      {croppedImageUrl && (\n        <div>\n          <Space vertical size={Space.Size.small_3x} />\n          <img\n            src={croppedImageUrl}\n            alt={\'Cropped image\'}\n            style={{ maxHeight: 300 }}\n          />\n          <Space vertical size={Space.Size.small_3x} />\n          <div>Cropped image</div>\n        </div>\n      )}\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      ComponentLinks,
      FigmaEmbed,
      Button,
      ImageCropper,
      Space,
      Playground,
      Props,
      useState,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
	{() => {
        const src = 'https://picsum.photos/1280/720';
        const [croppedImageUrl, setCroppedImageUrl] = useState(null);
        const [isOpen, setIsOpen] = useState(false);

        const onSaveBlob = image => {
          const formData = new FormData();
          formData.append('image', image); // fetch('/api/formdata', { method: 'POST', body: formData })

          if (croppedImageUrl) URL.revokeObjectURL(croppedImageUrl);
          setCroppedImageUrl(URL.createObjectURL(image));
        };

        const onSaveDataUrl = image => {// fetch('/api/base64', { method: 'POST', body: image })
        };

        const onClose = () => {
          setIsOpen(false);
        };

        return <div>
              <ImageCropper isOpen={isOpen} src={src} onSaveBlob={onSaveBlob} onSaveDataUrl={onSaveDataUrl} onCloseClick={onClose} mdxType="ImageCropper" />
              <Button onClick={() => setIsOpen(true)} mdxType="Button">Открыть ImageCropper</Button>
              {croppedImageUrl && <div>
                <Space vertical size={Space.Size.small_3x} mdxType="Space" />
                <img src={croppedImageUrl} alt={'Cropped image'} style={{
              maxHeight: 300
            }} />
                <Space vertical size={Space.Size.small_3x} mdxType="Space" />
                <div>Cropped image</div>
              </div>}
	      </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "поведение"
    }}>{`Поведение`}</h2>
    <FigmaEmbed node='?node-id=30119%3A44483' mdxType="FigmaEmbed" />
    <FigmaEmbed node='?node-id=31463%3A40844' mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "анатомия"
    }}>{`Анатомия`}</h2>
    <FigmaEmbed node='?node-id=30133%3A45197' height={1000} mdxType="FigmaEmbed" />
    <FigmaEmbed node='?node-id=30133%3A45368' height={1000} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "использование"
    }}>{`Использование`}</h2>
    <FigmaEmbed node='?node-id=30119%3A44488' height={800} mdxType="FigmaEmbed" />
    <FigmaEmbed node='?node-id=31463%3A40862' height={800} mdxType="FigmaEmbed" />
    <FigmaEmbed node='?node-id=31513%3A40164' height={800} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "пропы"
    }}>{`Пропы`}</h2>
    <Props of={ImageCropper} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      